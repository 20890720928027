import {  StateInquiry,  StateOrder } from './workflow/States'
import { PimcoreNode } from '../pimcore/PimcoreNode'
import { PimcoreApiUser } from '@/models/pimcore/PimcoreApiUser'



export type History = PimcoreNode & {
  action: string
  state: StateOrder | StateInquiry,
  datetime: string,
  branchOffice: string,
  apiUser: PimcoreApiUser,
}


export const History = (h): History => ({
  ...PimcoreNode(),
  action: h.action,
  state: h.state,
  datetime: h.datetime,
  branchOffice: h.branchOffice,
  apiUser: PimcoreApiUser(h.apiUser),
})
